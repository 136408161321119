import React from "react";
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import Tabbar from "@/components/tabbar";
import NotFound from "@/components/notFound";

const Home = lazy(() => import("@/pages/homePage"));

const OldMBTIHome = lazy(() => import("@/pages/oldMBTIHomePage")); //

const Category = lazy(() => import("@/pages/categoryPage"));
const Member = lazy(() => import("@/pages/memberPage"));
const Mine = lazy(() => import("@/pages/minePage"));

/**
 * 落地页（mbti、abo、animal、love）
 * */
const PaperMBTI = lazy(() => import("@/pages/paperPage/mbti"));
const PaperAbo = lazy(() => import("@/pages/paperPage/abo"));
const PaperAnimal = lazy(() => import("@/pages/paperPage/animal"));
const PaperLove = lazy(() => import("@/pages/paperPage/love"));
/**
 * 答题
 * */
const Answer = lazy(() => import("@/pages/paperPage/answer"));

/**
 * 报告列表
 * */
const Report = lazy(() => import("@/pages/paperPage/report"));

/**
 * 报告入口
 * */
const ReportResultMain = lazy(() =>
  import("@/pages/paperPage/report/result/main")
);
/**
 * 报告动画生成
 * */
const ReportGenerating = lazy(() =>
  import("@/pages/paperPage/report/generateing")
);

const Person = lazy(() => import("@/pages/minePage/personInfo"));
const SendHistoryPage = lazy(() => import("@/pages/sendHistoryPage"));
const ReceivePage = lazy(() => import("@/pages/receivePage"));
const SendPage = lazy(() => import("@/pages/sendPage"));
const TransPage = lazy(() => import("@/pages/transPage"));
const WithTrack = lazy(() => import("./withTrack"))
const GenerateReportPage = lazy(() => import("@/pages/generateReportPage"));
// 路由懒加载，需配合Suspense使用
const lazyLoad = (children) => {
  return <Suspense fallback={""}><WithTrack>{children}</WithTrack></Suspense>;
};

const AliRoutes = [
  {
    path: "/alink",
    element: <Tabbar />,
    children: [
      {
        index: true,
        element: lazyLoad(<Home />),
      },
      {
        path: "category",
        element: lazyLoad(<Category />),
      },
      {
        path: "member",
        element: lazyLoad(<Member />),
      },
      {
        path: "mine",
        element: lazyLoad(<Mine />),
      },
    ],
  },
  {
    path: "alink/paper",
    title: "MBTI人格测试",
    element: lazyLoad(<OldMBTIHome />),
  },
  {
    path: "/alink/web/paper",
    title: "MBTI人格测试",
    children: [
      {
        index: "/alink/web/paper",
        element: lazyLoad(<PaperMBTI />),
      },
      {
        path: "/alink/web/paper/abo",
        element: lazyLoad(<PaperAbo />),
      },
      {
        path: "/alink/web/paper/love",
        element: lazyLoad(<PaperLove />),
      },
      {
        path: "/alink/web/paper/animal",
        element: lazyLoad(<PaperAnimal />),
      },
      {
        path: "/alink/web/paper/report/:puid",
        element: lazyLoad(<Report />),
      },
      {
        path: "/alink/web/paper/answer/:puid/:id",
        element: lazyLoad(<Answer />),
      },
      {
        path: "/alink/web/paper/answer/:puid/:id/:type",
        element: lazyLoad(<Answer />),
      },
      {
        path: "/alink/web/paper/report/main/:productType/:orderId",
        element: lazyLoad(<ReportResultMain />),
      },
      {
        path: "/alink/web/paper/report/main/:productType/:orderId/:type",
        element: lazyLoad(<ReportResultMain />),
      },
      {
        path: "/alink/web/paper/report/generating/:paperOrderSn",
        element: lazyLoad(<ReportGenerating />),
      },
      
    ],
  },
  {
    path: "/alink/web/person",
    title: "个人信息",
    children: [
      {
        path: "/alink/web/person/info/:paperOrderSn",
        element: lazyLoad(<Person />),
      },
    ],
  },
  {
    path: "/alink/web/sendHistoryPage",
    title: "赠送记录",
    children: [
      {
        path: "/alink/web/sendHistoryPage",
        element: lazyLoad(<SendHistoryPage />),
      },
    ],
  },
  {
    path: "/alink/web/receivePage",
    title: "领取页面",
    children: [
      {
        path: "/alink/web/receivePage",
        element: lazyLoad(<ReceivePage />),
      },
    ],
  },
  {
    path: "/alink/web/sendPage",
    title: "赠送页面",
    children: [
      {
        path: "/alink/web/sendPage",
        element: lazyLoad(<SendPage />),
      },
    ],
  },
  {
    path: "/alink/web/transPage",
    title: "中转页面",
    children: [
      {
        path: "/alink/web/transPage",
        element: lazyLoad(<TransPage />),
      },
    ],
  },
  {
    path: "/alink/web/generateReport",
    title: "生成报告页",
    children: [
      {
        path: "/alink/web/generateReport",
        element: lazyLoad(<GenerateReportPage />),
      },
      {
        path: "/alink/web/generateReport/:type",
        element: lazyLoad(<GenerateReportPage />),
      }
    ],
  },
];

const AppRouter = () => {
  return useRoutes([
    {
      path: "/",
      element: <Tabbar />,
      children: [
        {
          index: true,
          element: lazyLoad(<Home />),
        },
        {
          path: "category",
          element: lazyLoad(<Category />),
        },
        {
          path: "member",
          element: lazyLoad(<Member />),
        },
        {
          path: "mine",
          element: lazyLoad(<Mine />),
        },
      ],
    },
    {
      path: "/web/paper",
      title: "MBTI人格测试",
      children: [
        {
          index: "/web/paper",
          element: lazyLoad(<PaperMBTI />),
        },
        {
          path: "/web/paper/mbti",
          element: lazyLoad(<PaperMBTI />),
        },
        {
          path: "/web/paper/abo",
          element: lazyLoad(<PaperAbo />),
        },
        {
          path: "/web/paper/love",
          element: lazyLoad(<PaperLove />),
        },
        {
          path: "/web/paper/animal",
          element: lazyLoad(<PaperAnimal />),
        },
        {
          //兼容旧版本
          path: "/web/paper/report/result/:orderId",
          element: lazyLoad(<ReportResultMain key={location.pathname} />),
        },
        {
          //兼容旧版本
          path: "/web/paper/report/result/:orderId/:type",
          element: lazyLoad(<ReportResultMain key={location.pathname} />),
        },
        {
          //兼容旧版本
          path: "/web/paper/report/result/:orderId/:type/:type2",
          element: lazyLoad(<ReportResultMain key={location.pathname} />),
        },
        {
          path: "/web/paper/report/main/:productType/:orderId",
          element: lazyLoad(<ReportResultMain key={location.pathname} />),
        },
        {
          path: "/web/paper/report/main/:productType/:orderId/:type",
          element: lazyLoad(<ReportResultMain key={location.pathname} />),
        },
        {
          path: "/web/paper/report/main/:productType/:orderId/:type/:type2",
          element: lazyLoad(<ReportResultMain key={location.pathname} />),
        },
        {
          path: "/web/paper/report/:puid",
          element: lazyLoad(<Report />),
        },
       
        {
          path: "/web/paper/report/generating/:paperOrderSn",
          element: lazyLoad(<ReportGenerating />),
        },
        {
          path: "/web/paper/answer/:puid/:id",
          element: lazyLoad(<Answer />),
        },
        {
          path: "/web/paper/answer/:puid/:id/:type",
          element: lazyLoad(<Answer />),
        },
      ],
    },
    {
      path: "/web/person",
      title: "个人信息",
      children: [
        {
          path: "/web/person/info/:paperOrderSn",
          element: lazyLoad(<Person />),
        },
      ],
    },   
    {
      path: "/web/sendHistoryPage",
      title: "赠送记录",
      children: [
        {
          path: "/web/sendHistoryPage",
          element: lazyLoad(<SendHistoryPage />),
        },
      ],
    },
    {
      path: "/web/receivePage",
      title: "领取页面",
      children: [
        {
          path: "/web/receivePage",
          element: lazyLoad(<ReceivePage />),
        },
      ],
    },
    {
      path: "/web/sendPage",
      title: "赠送页面",
      children: [
        {
          path: "/web/sendPage",
          element: lazyLoad(<SendPage />),
        },
      ],
    },
    {
      path: "/web/transPage",
      title: "中转页面",
      children: [
        {
          path: "/web/transPage",
          element: lazyLoad(<TransPage />),
        },
      ],
    },
    {
      path: "/web/generateReport",
      title: "生成报告页",
      element: lazyLoad(<GenerateReportPage />),
      children: [
        {
          path: "/web/generateReport",
          element: lazyLoad(<GenerateReportPage />),
        },
        {
          path: "/web/generateReport/:type",
          element: lazyLoad(<GenerateReportPage />),
        },
      ],
    },
    ...AliRoutes,
    { path: "*", element: <NotFound /> },
  ]);
};

export default AppRouter;
