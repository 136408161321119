import React, { useEffect } from "react";
import notFound from "@/assets/not-found.jpeg";

const NotFound = () => {
  useEffect(() => {
    // 埋点 - 页面开始加载
    window.tracert.sendEventImmediately({
      event_name: "error_page",
      event_params: {
        k0: "url",
        v0: location.href,
        k1: "ua",
        v1: navigator.userAgent,
      },
    });
  }, []);

  return (
    <div className="no-data">
      <img src={notFound} alt="no-data" />
    </div>
  );
};
export default NotFound;
