import axios from "@/utils/request";

const CONTENT_TYPE = { "Content-Type": "application/json" };
/**
 * 测算落地页
 * @param data
 * @returns {AxiosPromise}
 */
export function apiPaperDetail(params) {
  return axios.post("/paper/detail", params);
}

/**
 * 创建测试单
 * @param data
 * @returns {AxiosPromise}
 */
export function apiPaperCreateTestOrder(params) {
  return axios.post("/paper/createTestOrder", params, CONTENT_TYPE);
}

/**
 * 获取测试单问题
 * @param data
 * @returns {AxiosPromise}
 */
export function apiPaperList(params) {
  return axios.post("/paper/getPaper", params, CONTENT_TYPE);
}

/**
 * 提交测试问题
 * @param data
 * @returns {AxiosPromise}
 */
export function apiPaperSubmit(params) {
  return axios.post("/paper/submit", params);
}

/**
 * 查询订单列表
 * @param data
 * @returns {AxiosPromise}
 */
export function apiTestOrderByStatus(params) {
  return axios.post("/paper/getTestOrderByStatus", params);
}

/**
 * 获取媒体端id
 */
export function createMediaId(params) {
  return axios.post("/media/createId", params);
}

/**
 * 获取用户订单
 */
export function getUserOrderStatus(params) {
  return axios.post("/paper/getUserOrderStatus", params);
}

/**
 * 获取猜你喜欢推荐列表
 */
export function getAllBannerList(params) {
  return axios.post("/paper/getAllBannerList", params);
}

/**
 * 获取指定报告推荐位广告
 */
export function getBannerList(params) {
  return axios.post("/paper/getBannerList", params);
}

/**
 * 获取赠送记录列表
 */
export function getGiveRecords(params) {
  return axios.post("/paper/getGiveRecords", params);
}

/**
 * 获取当前赠送权益数据
 */
export function getGiveRightAmount(params) {
  return axios.post("/paper/getGiveRightAmount", params);
}

/**
 * 获取赠送码
 */
export function givePaper(params) {
  return axios.post("/paper/givePaper", params);
}

/**
 * 获取领取记录
 */
export function getReceiveRecords(params) {
  return axios.post("/paper/getRecevieRecords", params);
}

/**
 * 点击领取
 */
export function receivePaper(params) {
  return axios.post("/paper/receivePaper", params);
}

/**
 *
 * 获取赠送页面内容
 */
export function getGivePageInfo(params) {
  return axios.post("/paper/getGivePageInfo", params);
}

/**
 *
 * 获取最新已支付报告
 */
export function getLastReport(params) {
  return axios.post("/paper/getLastReport", params);
}

/**
 *
 * 微信openid获取uuid
 */
export function queryUuid(params) {
  return axios.post("/order/queryUuid", params);
}

/**
 *
 * 获取微信授权链接
 */
export function getAuthUrl(params) {
  return axios.post("/user/getAuthUrl", params);
}

/**
 *
 * 永久报告-提交手机号
 */
export function submitMobilePhone(params) {
  return axios.post("/paper/submitMobilePhone", params);
}
/**
 *
 *
 * 获取赠送权益支付信息
 */
export function getGivenProductInfo(params) {
  return axios.post("/paper/getGivenProductInfo", params);
}

/**
 *
 * 查询赠送码对应得订单状态
 */
export function getStatusBySendCode(params) {
  return axios.post("/paper/getStatusBySendCode", params);
}

