import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "lib-flexible";
import AppRouter from "./router";
import store from "./redux/store";
import { Provider } from "react-redux";
import {
  decodeQuery,
  getRandomStr,
  isMobile,
  getCurrentEnv,
} from "./utils/tools";
import Tracert from "./utils/tracert";

/**
 * 待打点必备参数生成完成后再创建实例
 */
window.tracert = new Tracert();

if (["test", "dev", "preonline"].includes(getCurrentEnv())) {
  const vConsole = new window.VConsole();
  console.log(vConsole);
}

/**
 * 如果不是移动端设备设置最大宽度
 */
if (!isMobile()) {
  const { clientWidth } = document.body;
  const width = clientWidth > 500 ? 500 : clientWidth;
  const fontSize = `${width / 10}px`;

  document.body.style.maxWidth = `${width}px`;
  document.body.style.margin = "0 auto";
  document.getElementsByTagName("html")[0].style.fontSize = fontSize;
}

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
