/**
 * 初始化的时候最好指定url
 */

import { generateUUID } from "./tools";
import { request } from "./xhr";
import moment from "moment";
import { getDomain, getRandomStr } from "./tools";
import axios from "./request";
import { createMediaId } from "../api/paper";

const prefix = "_jiujiu_";
const key = "__uuid__";
const domain = getDomain();
const url = domain?.data + "/api/userlog";
class Tracert {
  constructor(
    options = {
      url,
    }
  ) {
    /** 传入的配置 url等 **/
    this.options = options;
    /** 最大批量上传个数 **/
    this.maxCount = options.maxCount || 2;
    /** 批量上传时待上传列表 **/
    this.eventList = [];
    /** 失败次数 **/
    this.failCount = 0;
    /** uuid **/
    this.uuid = this.getDevideId();
    /** session_id */
    this.session_id = sessionStorage.getItem("session_id") || getRandomStr();
    /** channel */
    this.channel =
      sessionStorage.getItem("channel") ||
      this.decodeQuery(location.href)?.channel ||
      "";
    /** media */
    this.media =
      sessionStorage.getItem("media") ||
      this.decodeQuery(location.href)?.media ||
      "";
    /** clickid */
    this.clickid = this.decodeQuery(location.href)?.clickid || "";

    this.projectid = this.decodeQuery(location.href)?.projectid || "";
    this.promotionid = this.decodeQuery(location.href)?.promotionid || "";
    this.creativetype = this.decodeQuery(location.href)?.creativetype || "";

    /** media_id */
    this.media_id =
      sessionStorage.getItem("media_id") ||
      localStorage.getItem("media_id") ||
      "";

    sessionStorage.setItem("session_id", this.session_id);
    sessionStorage.setItem("channel", this.channel);
    sessionStorage.setItem("media", this.media);
    sessionStorage.setItem("clickid", this.clickid);

    if (this.clickid) {
      createMediaId({
        uuid: this.uuid,
        media: "juliang",
        link: location.href,
      }).then((res) => {
        const { code, data = {} } = res;
        const ua = navigator.userAgent;
        if (code === 200) {
          this.media_id = data?.media_id || "";
          sessionStorage.setItem("media_id", this.media_id);
          localStorage.setItem("media_id", this.media_id); //localstorage也存一份，避免会话切换时支付不回传，这个记录下单成功后删除

          this.sendEventImmediately({
            event_name: "got_media_id",
            event_params: {
              k0: "clickid",
              v0: this.clickid,
              k1:"url",
              v1:location.href,
              k2:"ua",
              v2:ua,
            },
          });
        } else {
          this.sendEventImmediately({
            event_name: "got_media_id_error",
            event_params: {
              k0: "clickid",
              v0: this.clickid,
             
              k1:"url",
              v1:location.href,
              k2:"ua",
              v2:ua,
              k3: "error_code",
              v3: code,

            },
          });
        }
      });
    }

    // 页面卸载时上报未上传的数据
    window.onunload = function () {
      const blob = new Blob([
        JSON.stringify(this.eventList, {
          type: "application/json",
        }),
      ]);
      //  navigator.sendBeacon(this.options.url, blob);
    };

    // 获取本地缓存，如果有未上传的，取出重新上传
    // this.reloadLocal();
  }

  /**
   * 获取当前页面的基础信息
   */
  getPageInfo() {
    const href = location.href;
    const [str, search = ""] = href.split("?");
    const [url, hash] = str.split("#");
    return {
      url,
      href,
      search,
      hash,
    };
  }

  /**
   * 获取当前设备的uuid
   */
  getDevideId() {
    let store = {};
    try {
      const item = localStorage.getItem(prefix);
      if (item !== null) {
        store = JSON.parse(item);
      }
    } catch (e) {
      console.log(e);
    }
    if (store[key] === undefined) {
      store[key] = localStorage.getItem("_uuid") || generateUUID();
      localStorage.setItem("_uuid", store[key]);
      localStorage.setItem(prefix, JSON.stringify(store));
    }
    return store[key];
  }

  /**
   * 获取浏览器
   */
  getBrowser() {
    const ua = navigator.userAgent;
    if (ua.indexOf("Firefox") != -1) {
      return "Mozilla Firefox";
    } else if (ua.indexOf("Opera") != -1 || ua.indexOf("OPR") != -1) {
      return "Opera";
    } else if (ua.indexOf("Trident") != -1) {
      return "Microsoft Internet Explorer";
    } else if (ua.indexOf("Edge") != -1) {
      return "Microsoft Edge";
    } else if (ua.indexOf("Chrome") != -1) {
      return "Google Chrome";
    } else if (ua.indexOf("Safari") != -1) {
      return "Apple Safari";
    } else {
      return "unknown";
    }
  }

  getProduct() {
    if (
      location.pathname?.includes("/abo") ||
      location.search?.includes("product=1")
    ) {
      return "abo";
    } else if (
      location.pathname?.includes("/animal") ||
      location.search?.includes("product=2")
    ) {
      return "animal";
    } else if (
      location.pathname?.includes("/love") ||
      location.search?.includes("product=3")
    ) {
      return "love";
    } else {
      return "mbti";
    }
  }
  /**
   * 获取当前时区
   */
  getTimeZone() {
    return parseInt((0 - new Date().getTimezoneOffset()) / 60);
  }

  /**
   * 解析链接携带的参数
   */
  decodeQuery(href) {
    const queryData = {};
    const url = new URL(href);
    const params = new URLSearchParams(url.search);
    for (let key of params.keys()) {
      queryData[key] = params.get(key);
    }
    return queryData;
  }

  /**
   * 保存打点数据到localStorage中
   */
  saveInLocal() {
    let store = {};
    try {
      const item = localStorage.getItem(prefix);
      if (item !== null) {
        store = JSON.parse(item);
      } else {
        store[key] = this.getDevideId();
      }
      store["data"] = this.eventList;
      localStorage.setItem(prefix, JSON.stringify(store));
    } catch (e) {}
  }

  /***
   * 获取本地未上传的打点数据
   */
  reloadLocal() {
    try {
      const item = localStorage.getItem(prefix);
      if (item !== null) {
        let store = JSON.parse(item);
        const { data = [] } = store;
        this.eventList = JSON.parse(
          JSON.stringify([...data, ...this.eventList])
        );
        localStorage.setItem(
          prefix,
          JSON.stringify({
            ...store,
            data: [],
          })
        );
        this.multipleSend();
      }
    } catch (e) {}
  }

  /**
   * 调用接口
   */
  sendData(data, type) {
    request({
      url: this.options.url,
      method: "POST",
      data,
    }).then((res) => {
      if (res?.code === 200) {
        // 上传成功后继续上传
        if (this.eventList.length >= this.maxCount && type === "multiple") {
          this.sendData(this.eventList.slice(0, this.maxCount), type);
          this.eventList = this.eventList.splice(this.maxCount);
        }
        // 能上传成功清空失败次数
        this.failCount = 0;
      }
    });
    // .catch((list = []) => {
    //   this.failCount++;
    //   // 重新尝试上传五次，失败后保存到localStorage
    //   if (this.failCount <= 5) {
    //     this.sendData(list, type);
    //   } else {
    //     this.failCount = 0;
    //     this.eventList = [...this.eventList, ...list];
    //     // this.saveInLocal();
    //   }
    // });
  }

  /**
   * 批量上报
   */
  multipleSend(type = "multiple") {
    if (this.eventList.length >= this.maxCount) {
      this.sendData(this.eventList.slice(0, this.maxCount), type);
      this.eventList = this.eventList.splice(this.maxCount);
    }
  }

  /**
   * 请求上报
   * @param { Object } data 上传的数据
   * @param { String } type single 单条上传，multiple 多条上传
   */
  call(data = {}, type = "single") {
    const ua = navigator.userAgent;
    const now = Date.now();
    const event_time = moment(now).format("YYYY/MM/DD HH:mm:ss.SSS"); // 此格式避免出现Safari兼容性问题
    const osMatch =
      ua.match(
        /(ipad|iphone|android|macintosh|windows|openharmony|harmonyos)/i
      ) || [];
    const os = osMatch[1] || "";
    const { href, url, search, hash } = this.getPageInfo();
    const query = this.decodeQuery(location.href);
    const uuid = this.getDevideId();
    const env = this.getBrowser();
    const product = this.getProduct();
    const time_zone = this.getTimeZone();
    const { clientHeight, clientWidth } = document.body;
    const { event_params = {} } = data;
    // 事件上传的拓展字段
    const expandParams = {
      k9: "product",
      v9: product,
      k8: "projectid",
      v8: this.projectid,
      k7: "promotionid",
      v7: this.promotionid,
      k6: "creativetype",
      v6: this.creativetype,
      ...event_params,
    };
    // 将外部传入event_params，合并到expandParams里
    // Object.entries(event_params).forEach(([key, value], index) => {
    //   const k = `k${7 + index}`;
    //   const v = `v${7 + index}`;
    //   expandParams[k] = key;
    //   expandParams[v] = value;
    // })
    data["event_params"] = expandParams;

    const defaultData = {
      event_name: "",
      event_time,
      channel: this.channel,
      env,
      session_id: this.session_id,
      media_id: this.media_id,
      media: this.media,
      page_name: document.title || url,
      timestamp: now,
      screen_h: window?.screen?.height || clientHeight,
      screen_w: window?.screen?.width || clientWidth,
      ...data, // 调用时传入，可覆盖👆数据

      // 以下内容固定上传，以防被篡改，其他参数自定义传入
      uuid, // 设备id
      os,
      time_zone,
    };

    // 默认单条上传
    if (type === "multiple") {
      this.eventList.push(defaultData);
      this.multipleSend(type);
    } else {
      this.sendData([defaultData], type);
    }
  }

  /**
   * 缓存上报
   */
  sendEvent(data) {
    this.call(data, "multiple");
  }
  /**
   * 上报缓存中的所有事件
   */
  flush() {
    if (this.eventList.length > 0) {
      this.sendData(this.eventList, "multiple");
      this.eventList = [];
    }
  }
  /**
   * 立即上报
   */
  sendEventImmediately(data) {
    //同时上报缓存中的事件
    this.flush();
    this.call(data, "single");
  }
}

export default Tracert;
